<template>
  <div class="bbr-reports--sales">
    <main-app-bar>
      <template v-slot:title>
        Sales Report
      </template>

      <template v-slot:top-actions>
        <v-btn
          class="bg-primary-gradient primary ml-auto"
          depressed
          @click="exportUserPrograms"
        >
          <v-icon class="mr-2">{{ icons.export }}</v-icon>
          Export Sales
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-7 px-10 pb-6">
      <div class="form-container">
        <div class="search-form">
          <v-row no-gutter>
            <v-col cols="12" sm="2">
              <v-menu
                :value="filter.starts_between.length === 1"
                transition="scale-transition"
                min-width="290px"
                :close-on-content-click="false"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDateText"
                    v-on="on"
                    label="Program Intake Date"
                    @input="searchUserPrograms"
                    clearable
                    @click:clear="filter.starts_between = []"
                    readonly
                    rounded
                    solo
                    flat
                  />
                </template>
                <v-date-picker
                  v-model="filter.starts_between"
                  @change="searchUserPrograms"
                  scrollable
                  no-title
                  range
                />
              </v-menu>
            </v-col>

            <v-col cols="12" sm="2">
              <v-menu
                :value="filter.ends_between.length === 1"
                transition="scale-transition"
                min-width="290px"
                :close-on-content-click="false"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDateText"
                    v-on="on"
                    label="Program End Date"
                    @input="searchUserPrograms"
                    clearable
                    @click:clear="filter.ends_between = []"
                    readonly
                    rounded
                    solo
                    flat
                  />
                </template>
                <v-date-picker
                  v-model="filter.ends_between"
                  @change="searchUserPrograms"
                  scrollable
                  no-title
                  range
                />
              </v-menu>
            </v-col>

            <v-col cols="12" sm="2">
              <v-select
                v-model="filter.user_country"
                :items="countries"
                label="Country"
                item-value="id"
                item-text="name"
                persistent-hint
                hide-details
                single-line
                clearable
                rounded
                solo
                flat
                @change="searchUserPrograms"
              />
            </v-col>

            <v-col cols="12" sm="2">
              <v-select
                v-model="filter.source"
                :items="extra.sourceTypes"
                label="Source"
                item-value="id"
                item-text="name"
                persistent-hint
                hide-details
                single-line
                clearable
                rounded
                solo
                flat
                @change="searchUserPrograms"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <search-programs-input
                v-model="filter.programs"
                label="Filter by Programs"
                placeholder="Programs and Challenge"
                @change="filterProgramChange"
                return-object
                is-for-filter
                clearable
                multiple
                rounded
                solo
                flat
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <list-table
            :loading="loading"
            :list="userPrograms"
            :meta="meta"
            @load-more="loadMoreUserPrograms"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiDownload } from '@mdi/js'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import MainAppBar from '@/layouts/shared/MainAppBar'
import SearchProgramsInput from '@/components/elements/programs/SearchProgramsInput'
import ListTable from './components/ListTable'

export default {
  name: 'SalesPage',

  components: {
    MainAppBar,
    SearchProgramsInput,
    ListTable,
  },

  data() {
    return {
      icons: { export: mdiDownload },
      loading: false,
    }
  },

  computed: {
    ...mapState({
      userPrograms: (state) => state.userProgram.list,
      meta: (state) => state.userProgram.listMeta,
      filter: (state) => state.userProgram.filter,
      extra: (state) => state.userProgram.extra,
      countries: (state) => state.countries.list,
    }),

    endDateText() {
      return this.filter.ends_between.join(' - ')
    },

    startDateText() {
      return this.filter.starts_between.join(' - ')
    },
  },

  created() {
    this.getCountries()
    this.fetchUserPrograms()
  },

  destroyed() {
    this.clearUserPrograms()
    this.clearUserProgramsMeta()
  },

  methods: {
    ...mapActions({
      getUserPrograms: 'userProgram/getUserPrograms',
      getCountries: 'countries/getCountries',
    }),

    ...mapMutations({
      clearUserPrograms: 'userProgram/clearUserPrograms',
      clearUserProgramsMeta: 'userProgram/clearUserProgramsMeta',
    }),

    filterProgramChange(value) {
      if (isEmpty(value)) {
        this.fetchUserPrograms()
      } else {
        this.searchUserPrograms()
      }
    },

    searchUserPrograms: debounce(function() {
      this.clearUserPrograms()
      this.fetchUserPrograms()
    }, 600),

    loadMoreUserPrograms() {
      if (this.meta.current_page < this.meta.last_page) {
        this.fetchUserPrograms(this.meta.current_page + 1)
      }
    },

    async fetchUserPrograms(page = 1) {
      if (this.loading) return

      this.loading = true

      await this.getUserPrograms({
        page,
        ...this.filter,
        programs: map(this.filter.programs, (program) => program.id),
      })

      this.loading = false
    },

    exportUserPrograms() {
      const params = {
        ...(this.filter.starts_between.length === 2 && {
          'filter[starts_between]': this.filter.starts_between.join(','),
        }),

        ...(this.filter.ends_between.length === 2 && {
          'filter[ends_between]': this.filter.ends_between.join(','),
        }),

        ...(this.filter.user_country && {
          'filter[user_country]': this.filter.user_country,
        }),

        ...(this.filter.source && {
          'filter[source]': this.filter.source,
        }),

        ...(this.filter.programs.length > 0 && {
          'filter[programs]': this.filter.programs
            .map((program) => program.id)
            .join(','),
        }),

        token: this.$store.state.auth.token.access_token,
      }

      const qs = map(params, (value, key) => key + '=' + value).join('&')

      window.open(`${this.$env.apiUrl}/user-programs/export?${qs}`, '_blank')
    },
  },
}
</script>
