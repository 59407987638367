<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <thead>
          <tr>
            <th>User</th>
            <th>Country</th>
            <th>Joined</th>
            <th>Program</th>
            <th>Validity</th>
            <th>Price</th>
            <th>Source</th>
          </tr>

          <tr>
            <th colspan="5">
              <span class="py-3 pl-0 caption font-weight-600">
                Entries ({{ meta.total }})
              </span>
            </th>
          </tr>
        </thead>

        <tbody
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="disableListScroll"
        >
          <tr :key="key" v-for="(userProgram, key) in list">
            <td>
              <div class="d-flex align-center py-1">
                <v-avatar color="primary" size="40">
                  <span class="white--text">
                    {{ $get(userProgram.user, 'full_name').charAt(0) }}
                  </span>
                </v-avatar>

                <div class="ml-4">
                  <p class="mb-0 font-weight-medium">
                    {{ $get(userProgram.user, 'full_name') }}
                  </p>

                  <p class="mb-0 text-caption">
                    {{
                      $date($get(userProgram.user, 'birthday')).format(
                        'MMM DD, YYYY'
                      )
                    }}
                  </p>

                  <p class="mb-0 text-caption grey--text">
                    {{ $get(userProgram.user, 'email') }}
                  </p>

                  <p class="mb-0 text-caption grey--text">
                    {{ $get(userProgram.user, 'phone_number') }}
                  </p>
                </div>
              </div>
            </td>

            <td>{{ $get(userProgram.user, 'country.name') }}</td>

            <td>
              {{ $get(userProgram.user, 'created_at') | formatedDate }}
            </td>

            <td>
              <p class="mb-0 font-weight-medium">
                {{ $get(userProgram.program, 'package.name') }}
              </p>

              <p class="mb-0 text-caption">
                {{ $get(userProgram.program, 'name') }}
              </p>

              <p class="mb-0 text-caption grey--text">
                <span>
                  {{ $date(userProgram.starts_at).format('MMM DD, YYYY') }}
                </span>
                -
                <span>
                  {{ $date(userProgram.ends_at).format('MMM DD, YYYY') }}
                </span>
              </p>
              <p
                v-if="userProgram.trial_days_left"
                class="mb-0 text-caption primary--text"
              >
                <span>{{ userProgram.trial_days_left }}</span>
                {{ userProgram.trial_days_left > 1 ? 'days left' : 'day left' }}
              </p>
              <p v-else class="mb-0 text-caption primary--text">
                Trial Expired
              </p>
            </td>

            <td>{{ userProgram.validity_variant }}</td>

            <td nowrap>
              <template v-if="userProgram.amount && userProgram.currency">
                {{ userProgram.amount.toFixed(2) + ' ' + userProgram.currency }}
              </template>
            </td>

            <td>{{ userProgram.source }}</td>
          </tr>
        </tbody>

        <table-skeleton-loader v-if="loading" column="8" row />
      </v-simple-table>

      <no-list v-if="!hasUserPrograms && !loading" details="No Sales Data" />

      <div
        v-if="meta.last_page > 1 && inLastPage"
        class="d-flex justify-center mt-6"
      >
        <h1 class="grey--text headline">
          You have reached the bottom of the page
        </h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import NoList from '@/components/elements/NoList'
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'

export default {
  name: 'ListTable',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    list: {
      type: Array,
      default: () => [],
      required: true,
    },

    meta: {
      type: Object,
      required: true,
    },
  },

  computed: {
    inLastPage() {
      return this.meta.current_page === this.meta.last_page
    },

    hasUserPrograms() {
      return !!this.list.length
    },

    disableListScroll() {
      return this.loading || this.inLastPage
    },
  },

  methods: {
    loadMore() {
      this.$emit('load-more')
    },
  },
}
</script>
